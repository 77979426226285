<template>
  <portal to="portal_popup">
    <modal-window :modal_open="true" title="Edit Account Code" @close="$emit('close')">
      <div class="font-medium">Account Code</div>
      <div>
        <input v-model="customer.financial.accountCode" class="form-control" />
      </div>
      <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
        <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
          Cancel
        </button>
        <button class="btn-action btn-lg" @click.prevent.stop="saveAccountCode()">
          Save Changes
        </button>
      </div>
    </modal-window>
  </portal>
</template>

<script>
import validate from 'validate.js';
import { mapActions } from 'vuex';

export default {
  props: ['customer'],
  methods: {
    ...mapActions({
      customerUpdate: 'user/customerUpdateRaw',
    }),
    async saveAccountCode() {
      this.$emit('isLoading', true);
      const validationErrors = validate(
        { account_code: this.customer.financial.accountCode },
        {
          account_code: {
            presence: { allowEmpty: false },
            length: {
              maximum: 64,
            },
          },
        },
      );
      if (validationErrors) {
        this.$emit('isLoading', false);
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }
      await this.customerUpdate({
        CustomerId: this.customer.customerId,
        Financial: {
          accountCode: this.customer.financial.accountCode,
        },
      });
      this.$emit('isLoading', false);
      this.$emit('close');
    },
  },
};
</script>
